<template>
  <div>
    <page-header
      name="Configurações de Sistema"
    >
    </page-header>
    <b-row>
      <b-col md="4">
        <SystemSettingsCard
          card-body="Ajuste as configurações de servidor para envio de e-mail da plataforma."
          card-title="Servidor SMTP"
          icon="MailIcon"
          type="smtp"
          @open="openModal('smtp-settings')"
        />
      </b-col>
      <b-col md="4">
        <SystemSettingsCard
          card-body="Ajuste os conteúdos de e-mails enviados aos usuários de sistema e participantes de promoções."
          card-title="Templates de E-mail"
          icon="MailIcon"
          type="email-templates"
          @open="redirectTo('mail.list')"
        />
      </b-col>
      <b-col md="4">
        <SystemSettingsCard
          card-body="Configurações de SMS para envio do código de validação do usuário."
          card-title="Provedor SMS"
          icon="MessageSquareIcon"
          type="sms-provider"
          @open="openModal('sms-provider')"
        />
      </b-col>
    </b-row>

    <SystemSettingsSmtp :open="openSmtpModal" @onClose="onClose" />

    <SystemSettingsSMSProvider :open="openSMSProviderModal" @onClose="onClose" />
  </div>
</template>

<script>

import SystemSettingsSmtp from '@/views/system-settings/SystemSettingsSmtp.vue'
import SystemSettingsCard from '@/components/app/system-settings/SystemSettingsCard.vue'
import SystemSettingsSMSProvider from "@/views/system-settings/SystemSettingsSMSProvider.vue";

export default {
  name: 'SystemSettingsList',
  components: {
    SystemSettingsSMSProvider,
    SystemSettingsCard,
    SystemSettingsSmtp,
  },
  data() {
    return {
      currentKeyModal: null,
      openSmtpModal: false,
      openSMSProviderModal: false,
    }
  },
  methods: {
    openModal(key) {
      this.currentKeyModal = key
      if (key === 'smtp-settings') {
        this.openSmtpModal = true
      }
      if (key === 'sms-provider') {
        this.openSMSProviderModal = true
      }
    },
    onClose() {
      if (this.currentKeyModal === 'smtp-settings') {
        this.openSmtpModal = false
      }
      if (this.currentKeyModal === 'sms-provider') {
        this.openSMSProviderModal = false
      }
      this.currentKeyModal = null
    },
    redirectTo(nameRoute) {
      this.$router.push({ name: nameRoute })
    },
  },
}
</script>

<style scoped>

</style>
