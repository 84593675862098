<template>
  <b-card>
    <b-card-title>
      <feather-icon :icon="icon" size="20"/>&nbsp;
      {{ cardTitle }}
    </b-card-title>
    <b-card-text>
      {{ cardBody }}
    </b-card-text>
    <b-row>
      <b-col>
        <b-button block variant="primary" @click="open">
          Acessar
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>

export default {
  name: 'SystemSettingsCard',
  props: {
    type: {
      required: true,
      type: String,
    },
    icon: {
      required: true,
      type: String,
    },
    cardTitle: {
      required: true,
      type: String,
    },
    cardBody: {
      required: true,
      type: String,
    },
  },
  methods: {
    open() {
      this.$emit('open', this.type)
    },
  },
}
</script>

<style scoped>

</style>
