<template>
  <b-modal
    id="modalSystemSettingsSmtp"
    v-model="isOpen"
    title="Configurações do Servidor SMTP"
    hide-header-close
    centered
    ok-title="Salvar"
    cancel-title="Fechar"
    no-close-on-backdrop
    @hide="onHide"
    @hidden="onHide"
    @ok="handleOk"
    @cancel="onClose"
  >
    <b-row class="align-items-center">
      <b-col md="6">
        <i2-form-input
          id="settings-from-name"
          v-model="settings.name_from"
          name="settings-from-name"
          label="Nome de Origem"
          placeholder="Exemplo de Nome"
          type="text"
          rules="required"
        />
      </b-col>
      <b-col md="6">
        <i2-form-input
          id="settings-from-email"
          v-model="settings.email_from"
          name="settings-from-email"
          label="E-mail de Origem"
          placeholder="nao-responder@globalpromocoes.com"
          type="text"
          rules="required"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <i2-form-input
          id="settings-host"
          v-model="settings.host"
          name="settings-host"
          label="Servidor"
          placeholder="smtp.exemplo.com"
          type="text"
          rules="required"
        />
      </b-col>
      <b-col md="6">
        <i2-form-input
          id="settings-port"
          v-model="settings.port"
          name="settings-port"
          label="Porta"
          placeholder="2525"
          type="text"
          rules="required"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <i2-form-input
          id="settings-port"
          v-model="settings.username"
          name="settings-username"
          label="Usuário"
          placeholder="exemplo@dominio.com"
          type="text"
          rules="required"
        />
      </b-col>
      <b-col md="6">
        <i2-form-input
          id="settings-password"
          v-model="settings.password"
          name="settings-password"
          label="Senha"
          placeholder="senha"
          type="text"
          rules="required"
        />
      </b-col>
    </b-row>
    <b-row class="align-items-center">
      <b-col md="6">
        <i2-form-input
          id="settings-encryption"
          v-model="settings.encryption"
          name="settings-encryption"
          label="Encriptação"
          placeholder="tls"
          type="text"
          rules="required"
        />
      </b-col>
      <b-col md="6" class="text-center">
        <b-button block size="sm" variant="primary" @click="testSettings">
          Testar Configuração
        </b-button>
      </b-col>
    </b-row>
    <b-row v-show="errorMessage">
      <b-col>
        <b-alert
          variant="danger"
          show
        >
          <h4 class="alert-heading">
            Aviso!
          </h4>
          <div class="alert-body">
            <small>{{ errorMessage }}</small>
          </div>
        </b-alert>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>

export default {
  name: 'SystemSettingsSmtp',
  components: {
  },
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      errorMessage: '',
      settings: {
        host: '',
        port: '',
        username: '',
        password: '',
        encryption: '',
        name_from: '',
        email_from: '',
      },
    }
  },
  watch: {
    open(value) {
      this.isOpen = value
      if (value) {
        this.getData()
      }
    },
  },
  methods: {
    getData() {
      this.$http.get('system-settings/smtp').then(
        response => {
          if (response.settings !== null) {
            this.settings = response.settings
          }
        },
      )
    },
    handleOk(bvModal) {
      bvModal.preventDefault()
      let withError = false
      for (let prop in this.settings) {
        if (this.settings[prop] === '') {
          withError = true
        }
      }

      if (withError) {
        this.$notify.error('Todos os campos são de preenchimento obrigatório.')
        return false
      }
      this.save()
      return true
    },
    async save() {
      const response = await this.$http.put('system-settings/smtp', this.settings)
      this.settings = response.settings
      this.$notify.success('Configuração de SMTP salva com sucesso.')
      this.onClose()
    },
    onClose() {
      this.errorMessage = ''
      this.$emit('onClose', true)
    },
    async testSettings() {
      const response = await this.$http.post('/system-settings/smtp/test', this.settings)
      this.errorMessage = ''
      if (response && response.error === 500) {
        this.$notify.error('Não foi possível enviar o e-mail de teste. Verifique a mensagem de erro na tela.')
        this.errorMessage = response.error_message
      } else {
        this.$notify.success('Envio de teste realizado com sucesso. Verifique a sua caixa de entrada.')
      }
    },
    onHide() {
      return false
    },
  },
}
</script>

<style scoped>

</style>
